<template>
  <div class="qingwu">
    <div class="admin_main_block">
      <div class="admin_breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/Admin/index' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item>代理中心</el-breadcrumb-item>
          <el-breadcrumb-item>代理审核</el-breadcrumb-item>
          <el-breadcrumb-item>审核列表</el-breadcrumb-item>
          <el-breadcrumb-item>详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="admin_main_block admin_m15">
      <el-row :gutter="40">
        <el-col :span="24">
          <span class="grid-divider">基本信息</span>
          <el-divider></el-divider>
        </el-col>
        <el-col :span="span" class="hight">
          <div class="sub-title">类型</div>
          <el-select
            v-model="selectExamType"
            disabled="true"
            placeholder="请选择"
          >
            <el-option
              v-for="(rs, index) in examType"
              :key="index"
              :label="rs"
              :value="index"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="span" class="hight">
          <div class="sub-title">真实姓名</div>
          <el-input
            placeholder=""
            v-model="infoDetail.realName"
            :disabled="true"
          ></el-input>
        </el-col>
        <el-col :span="span" class="hight">
          <div class="sub-title">身份证号</div>
          <el-input
            placeholder=""
            v-model="infoDetail.idCardNo"
            :disabled="true"
          ></el-input>
        </el-col>
        <el-col :span="span" class="hight">
          <div class="sub-title">服务区域</div>
          <el-input
            placeholder=""
            v-model="infoDetail.serverArea"
            :disabled="true"
          ></el-input>
        </el-col>
        <el-col :span="span" class="hight">
          <div class="sub-title">手机号码（登录APP的账号）</div>
          <el-input
            placeholder=""
            v-model="infoDetail.phone"
            :disabled="true"
          ></el-input>
        </el-col>
        <el-col :span="span" class="hight">
          <div class="sub-title">账号名称</div>
          <el-input
            placeholder=""
            v-model="infoDetail.userName"
            :disabled="true"
          ></el-input>
        </el-col>
        <el-col :span="span" class="hight">
          <div class="sub-title">区域服务商(可不填)</div>
          <el-input
            placeholder=""
            v-model="infoDetail.officeArea"
            :disabled="true"
          ></el-input>
        </el-col>
        <el-col :span="span" class="hight">
          <div class="sub-title">区域运营中心(可不填)</div>
          <el-input
            placeholder=""
            v-model="infoDetail.operationArea"
            :disabled="true"
          ></el-input>
        </el-col>
      </el-row>
    </div>
    <div class="admin_main_block admin_m15">
      <el-row :gutter="40">
        <el-col :span="24">
          <span class="grid-divider">证件信息</span>
          <el-divider></el-divider>
        </el-col>
        <el-col :span="6">
          <div class="sub-title">身份证人像面</div>
          <el-image
            class="el-imag"
            :src="infoDetail.idCardImage ? infoDetail.idCardImage[0] : ''"
            :preview-src-list="[infoDetail.idCardImage[0]]"
            fit="contain"
          >
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </el-col>
        <el-col :span="6">
          <div class="sub-title">身份证国徽面</div>
          <el-image
            class="el-imag"
            :src="
              infoDetail.idCardImage.length > 1 ? infoDetail.idCardImage[1] : ''
            "
            :preview-src-list="[infoDetail.idCardImage[1]]"
            fit="contain"
          >
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </el-col>
        <el-col :span="6">
          <div class="sub-title">手持身份证</div>
          <el-image
            class="el-imag"
            :src="
              infoDetail.idCardImage.length > 2 ? infoDetail.idCardImage[2] : ''
            "
            :preview-src-list="[infoDetail.idCardImage[2]]"
            fit="contain"
          >
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </el-col>
        <el-col :span="6"> </el-col>
      </el-row>
      <el-row :gutter="40" v-if="infoDetail.state == 1">
        <el-col :span="24">
          <span class="grid-divider">操作纪录</span>
          <el-divider></el-divider>
        </el-col>
        <el-col :span="24">
          <el-timeline>
            <el-timeline-item
              v-for="(activity, index) in activities"
              :key="index"
              :timestamp="activity.timestamp"
            >
              {{ activity.content }}
            </el-timeline-item>
          </el-timeline>
        </el-col>
      </el-row>
      <el-row gutter="40">
        <el-col>
          <el-card>
            <el-button
              icon="el-icon-check"
              type="primary"
              @click="dialogSendVisible = true"
            >
              审核
            </el-button>
          </el-card>
        </el-col>
      </el-row>
      <el-dialog
        title="代理审核"
        :visible.sync="dialogSendVisible"
        width="22%"
        :before-close="handleSendClose"
      >
        <el-form ref="form" :model="form" label-width="90px">
          <el-form-item label="不通过理由" prop="remark">
            <el-input
              type="textarea"
              placeholder="请输入不通过理由"
              v-model="form.remark"
              clearable
              maxlength="250"
            ></el-input>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="success(2)">审核不通过</el-button>
          <el-button type="primary" @click="success(1)">审核通过</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      infoDetail: {
        idCardImage: [],
      },
      where: {
        businessId: "",
        examType: "",
      },
      span: 6,
      showLogs: false,
      selectExamType: "",
      dialogSendVisible: false,
      examType: {
        0: "BD",
        1: "服务商",
        2: "运营中心",
        3: "商户审核",
        4: "会员368审核",
      },
      form: {
        remark: "",
      },
      activities: [
        {
          content: "活动按期开始",
          timestamp: "2018-04-15",
        },
        {
          content: "通过审核",
          timestamp: "2018-04-13",
        },
        {
          content: "创建成功",
          timestamp: "2018-04-11",
        },
      ],
    };
  },
  methods: {
    success(val) {
      //state 1:通过；2：不通过
      if (val == 2 && this.form.remark == "") {
        this.$message({
          type: "error",
          message: "请填写不通过理由!",
        });
        return;
      }
      const data = {
        businessId: this.$route.query.businessId,
        examType: this.$route.query.examType,
        id: this.$route.query.businessId,
        remark: this.form.remark,
        state: val,
      };
      this.post(data);
      this.dialogSendVisible = false;
    },
    infoData() {
      this.$postFormData(this.$api.examhistoryDetail, this.where).then(
        (res) => {
          if (res.code == 1) {
            this.infoDetail = res.data;
          }
        }
      );
    },
    post(data) {
      this.$post(this.$api.auditAgentService, data).then((res) => {
        if (res.code == 1) {
          this.$message({
            type: "success",
            message: "成功!",
          });
          this.$router.go(-1);
        } else {
          this.$message({
            type: "error",
            message: "失败!",
          });
        }
      });
    },
    handleSendClose() {
      this.dialogSendVisible = false;
    },
  },
  created() {
    this.where = this.$route.query;
    this.infoData();
    this.selectExamType = this.where.examType;
  },
};
</script>
<style lang="scss" scoped>
.hight {
  height: 80px;
}
.el-row {
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.el-tab-pane {
  padding-top: 10px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.el-divider--horizontal {
  margin: 12px 0px 12px 0px;
}

.sub-title {
  min-height: 26px;
}

.el-imag {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 260px;
  height: 178px;
}

.el-imag:hover {
  border-color: #409EFF;
}

.el-icon-picture-outline {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.layadmin-contact-box {
  width: 300px;
  display: inline-block;
  border: 1px solid #e7eaec;
  padding: 40px 0 40px 0;
}

.layadmin-text-center {
  text-align: center;
}

.layadmin-text-center .el-image {
  max-width: 40px;
  border-radius: 50%;
  margin-top: 5px;
  border: 1px dashed #d9d9d9;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 40px;
  height: 40px;
}

.el-card {
  text-align: center;
}

.header-form {
  height: 40px;
  padding: 10px 0 10px 0;
}
</style>
